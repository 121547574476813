import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ContentfulRichText from 'components/ContentfulRichText';

class ContactPage extends React.Component {
  render() {
    const data = this.props.data.allContentfulPage.edges[0].node;
    const document = data.body.json;
    return (
      <Layout>
        <ContentfulRichText document={document} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ContactPageQuery {
    allContentfulPage(filter: { slug: { eq: "contact" } }) {
      edges {
        node {
          slug
          body {
            json
          }
        }
      }
    }
  }
`;

export default ContactPage;
